.thumbnail-video_custom {
    position: relative;
    width: 8rem;
    height: 8rem;
    border-radius: 5px;
    background: black;
    margin-right: 0.3rem;
    margin-top: 0.3rem;
    cursor: pointer;
    video {
        width: 100%;
        height: 100%;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            color: #fff;
        }
    }
}
