@import "../../../../scss/variables";
.info_member {
    background: #fff;
    padding: 1rem 0;

    &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 0.8rem 1.2rem;

        &-title {
            font-weight: 600;
            font-size: 1.6rem;
        }
        &-icon {
            transition: 0.2s;
            font-size: 1.6rem;
            color: $neutral-500;
        }
    }

    &-interact {
        position: relative;
        overflow-y: hidden;
        max-height: 300px;
        transition: max-height 0.2s ease;
        overflow: hidden;
        position: relative;

        &_button.flex-end {
            justify-content: flex-end;
        }

        &_button {
            position: absolute;
            right: 1.2rem;
            display: flex;
            justify-content: space-between;
            width: 20%;

            .cirle-button.green {
                background: #50a960;
                transition: 0.4s;
                svg {
                    color: #fff;
                }
            }

            .cirle-button.red {
                background: #db342e;
                transition: 0.4s;
                svg {
                    color: #fff;
                }
            }
            .authorize-toggle.red:hover {
                background: #fff;

                svg {
                    color: #db342e;
                }
            }

            .authorize-toggle.green:hover {
                background: #fff;

                svg {
                    color: #db342e;
                }
            }
            .copy-link {
                transition: 0.4s;
            }

            .copy-link:hover {
                background: #fff;
                color: $blue-meetdy;
            }

            .cirle-button {
                width: 3rem;
                height: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(210, 239, 255, 0.5);
                border-radius: 50%;
            }
            svg {
                font-size: 1.6rem;
                color: $base-color;
            }
        }
        &_link-des {
            color: $blue-meetdy;
            font-size: 1.2rem;
            width: 70%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            word-wrap: break-word;
        }

        &-amount {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0.8rem 1.4rem;
            cursor: pointer;
        }
        &-amount-icon {
            font-size: 1.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.4rem;
            transition: 0.2s;
        }
        &-amount-text {
            padding-left: 1rem;
        }
    }

    &-interact-amount:hover {
        background: $grey-500;
    }
}
