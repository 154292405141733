@import "../../../../scss/variables";
.list_icon-reaction {
    position: absolute;
    top: -140%;
    left: 14px;
    transform: translateX(-50%);

    border: 1px solid black;
    padding: 0.6rem 0.5rem;
    display: none;

    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    background: $white-color;
    border: 0.05rem solid $grey-300;
    z-index: 1000;

    span {
        font-size: 16px;
        transition: 0.2s;
        margin: 0 0.4rem;
    }

    span:hover {
        transform: scale(1.5);
    }
}

.list_icon-reaction::after {
    content: "";
    width: 100%;
    height: 20px;
    bottom: -25%;

    left: 0;
    position: absolute;
}

.reaction {
    font-size: 14px;
    padding: 2.2px 6px;
    text-align: center;

    background: $white-color;
    cursor: pointer;
    border: 0.05rem solid $grey-300;
    position: absolute;
    border-radius: 50%;
    display: inline;

    // right: 10%;
    bottom: 0;
    transform: translateY(50%);
    visibility: hidden;
    z-index: 100;
}

.reaction.media {
    position: absolute;
}

.reaction.right {
    right: 1.4rem;
}
.reaction.left {
    left: 1.4rem;
}
