@import "../../../../scss/variables";
.modal-classify_wrapper {
    .modal-classify_add {
        font-size: 1.6rem;
        display: inline-flex;
        align-items: center;
        color: $blue-meetdy;

        cursor: pointer;
        margin-top: 1rem;
        padding: 0.4rem 0.8rem;
        transition: 0.2s;
        border-radius: 5px;
    }

    .modal-classify_add:hover {
        background: $blue-700;
    }

    .modal-classify_title {
        font-size: 1.3rem;
        font-weight: 500;
    }

    .modal-classify_list-classify {
        margin-top: 1rem;
        .modal-classify-item:hover .icon-classify {
            visibility: visible;
        }

        .modal-classify-item:hover .modal-classify-item-amount {
            visibility: hidden;
        }
        .modal-classify-item {
            padding: 0.8rem 0.8rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            background: $white-smoke;
            border-radius: 5px;
            margin-bottom: 1rem;
            position: relative;

            &--left,
            &--right {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .icon-classify {
                cursor: pointer;
                width: 28px;
                height: 28px;
                font-size: 1.8rem;

                display: flex;
                align-items: center;
                justify-content: center;

                margin: 0 0.4rem;
                transition: background 0.2s;
                border-radius: 3px;
                visibility: hidden;
            }

            .icon-classify:hover {
                background: $grey-300;
            }

            // $grey-500

            .classify-item-tag {
                font-size: 1.8rem;
            }

            .classify-item-name {
                margin-left: 1rem;
                font-size: 1.6rem;
            }

            .modal-classify-item-amount {
                position: absolute;
                right: 1rem;
                top: 0;
                transform: translateY(50%);

                font-weight: 500;
                visibility: visible;
            }
        }
    }
}

.modal-add_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    &--icon {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;

        justify-content: center;
        border-radius: 50%;
        margin-right: 1rem;
        transition: 0.2s;
        cursor: pointer;
    }

    &--icon:hover {
        background: $grey-300;
    }
}

.modal-add-classify_wrapper {
    .modal-add-classify--title {
        font-size: 14px;
        font-weight: 500;
    }
    .modal-add-classify--input {
        margin-top: 1rem;
        .tag-select-icon {
            font-size: 1.8rem;
            cursor: pointer;
        }
    }
    .check-name-classify {
        margin-top: 1rem;
    }
}
.popup-change-color {
    display: flex;
    flex-direction: column;
    > span {
        font-size: 13px;
        font-weight: 500;
    }
    .list-color {
        display: flex;
        flex-direction: row;
        padding: 0.5rem 0;
    }
    .popup-color-item {
        width: 25px;
        height: 25px;
        cursor: pointer;
    }
    .popup-color-item ~ .popup-color-item {
        margin-left: 0.4rem;
    }
}
