@import "../../../../scss/variables";

$icon-demention: 48px;

ul {
    margin-bottom: 0;
}

.pop_up-personal {
    display: flex;
    flex-direction: column;
    &--item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;

        transition: 0.1s;
        padding: 0.4rem 0rem;
        &-text {
            padding-left: 0.8rem;
            padding-right: 3rem;
        }
    }

    &--item:hover {
        color: $strong-blue;
    }
}

#sidebar_wrapper {
    height: 100vh;
    background: $blue-meetdy;
    display: flex;
    flex-direction: column;
    // width: 64px;

    .sidebar-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .sidebar_nav {
            padding: 2rem 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;

            .link-icon {
                width: 100%;
            }

            &_item {
                display: flex;
                align-items: center;
                justify-content: center;

                transition: 0.2s;
                cursor: pointer;
                width: 100%;
                height: 6rem;

                &--icon {
                    height: 100%;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    color: $white-color;
                    .anticon {
                        color: $white-color;
                        font-size: 2.5rem;
                    }
                }
            }
            &_item.active {
                background: $base-color;
            }

            &_item.active:hover {
                background: $base-color;
            }

            &_item.icon-avatar {
                margin-bottom: 2rem;

                .user-icon-navbar {
                    border-radius: 50%;
                }
            }

            &_item:hover {
                background: $blue-light;
                color: #fff;
            }
            &_item.icon-avatar:hover {
                background: #4c92ff;
            }
        }
    }
}
