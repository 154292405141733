$demension: 80px;
$demension_icon: 26px;

.upload-avatar {
    &_image {
        border: 0.5px solid #c4c4c4;
        background: #c4c4c4;
        img {
            max-height: 100%;
            display: block;
        }
    }
    &_text-select,
    &_image {
        border: 1px solid #cfff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height: $demension;
        width: $demension;
    }
    &_text-select {
        font-size: 1.3rem;
        cursor: pointer;
        text-align: center;
    }

    &_icon {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        width: $demension_icon;
        height: $demension_icon;
        border-radius: 50%;

        background-color: #fff;

        border: 0.5px solid #c4c4c4;
        label {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
.upload-avatar_default-avatar {
    position: relative;
    height: $demension;
    width: $demension;
}
