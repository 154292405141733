$blue-color: blue;
$white-color: #ffffff;
$strong-blue: #396edd;
$blue-light: #58aeff;
$light-grey: #e8eaef;
$thumbnail-text: #87929e;
$light-grey: #f4f5f7;
$normal-text: #606c7a;
$black-color: #282a35;
$red-color: #de433e;
$white-smoke: #f4f5f7;
$ghost-white: #fafafb;
$grey-300: #e1e4ea;
$blue-1f4: #1f4060;
$grey-400: #e8eaef;
$neutral-500: #72808e;
$base-color: #0068ff;
$blue-meetdy: #4c92ff;
$silver: #b3bbc8;
$blue-700: #e5efff;
$grey-500: #eeeff2;
$gradident-blue: linear-gradient(90deg, $base-color, $blue-meetdy);
$dark-gray: #abb3bb;
$light-cyan: #d2efff;
$white-smoker: #f5f5f5;
$purple: #814096;
$pink: #f83292;
$gradient: linear-gradient(90deg, $blue-meetdy, $base-color);
