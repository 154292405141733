@import "../../scss/variables";


.carousel-slider--item {
    display: flex ;
    justify-content: center;
    flex-direction: column ;
    align-items: center;

    // height: 50vh;
    text-align: center;

    .slider-img {
        height: 260px;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .slider-content {
        &--title {
            font-size: 18px;
            font-weight: 500;
            color: $base-color;
        }
    }
}
