.friend-card {
    margin-left: 5rem;
    margin-right: 5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 3px;
    &_info-user {
        display: flex;
        align-items: center;
        width: 100%;
    }
    &_name {
        margin-left: 1rem;
        font-size: 1.6rem;
    }

    &_interact {
        display: flex;
        align-items: center;
    }

    &_button--accept {
        margin-left: 1rem;
    }
    &_interact-mobile {
        display: none;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
    }

    @media only screen and (min-width: 577px) and (max-width: 767px) {
    }
    @media only screen and (max-width: 576px) {
        margin-left: 2rem;
        margin-right: 2rem;
        &_interact-mobile {
            display: flex;
            margin-top: 1rem;
        }
        &_button--accept {
            margin-left: 1rem;
        }
        &_cancel-request {
            margin-left: 0;
        }
        &_interact {
            display: none;
        }
    }
}

.friend-card:first-child,
.friend-card:last-child {
    margin-top: 2rem;
}

.friend-card:nth-child(n + 1) {
    margin-top: 1rem;
}
