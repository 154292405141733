@import "../../../../scss/variables";
$demension: 6rem;

.sticker-item_list-sticker {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .sticker-item_img {
        width: $demension;
        height: $demension;
        border-radius: 5px;
        transition: 0.2s;
        overflow: hidden;
        cursor: pointer;
        margin: 0.5rem 0.5rem;

        img {
            max-height: 90%;
            max-width: 90%;
        }
    }

    .sticker-item_img:hover {
        background: $grey-500;
    }
}

.sticker-item_name {
    font-size: 1.6rem;
    font-weight: 500;
    margin-left: 0.5rem;
}

.sticker-item {
    margin-top: 1rem;
}
