@import "../../../../scss/variables";
.channel {
    background: #fff;
    padding: 1rem 0;

    &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 0.8rem 1.2rem;

        &-title {
            font-weight: 600;
            font-size: 1.6rem;
            .total-channel-notify {
                color: $red-color;
            }

            .total-channel-notify::before {
                content: " - ";
                color: initial;
            }
        }

        &-icon {
            transition: 0.2s;
            font-size: 1.6rem;
            color: $neutral-500;
        }
    }

    &-interact {
        position: relative;
        overflow-y: hidden;
        max-height: 300px;
        transition: max-height 0.2s ease;
        overflow: hidden;
        position: relative;

        .notify-amount {
            position: absolute;
            width: 20px;
            height: 20px;
            background: $red-color;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            right: 1.4rem;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.2rem;
            transition: 0.2s;
        }

        &-button {
            margin: 0rem 1.4rem;
            margin-top: 1rem;
        }

        &-button button {
            width: 100%;
            font-weight: 600;
            border: none;
            outline: none;

            padding: 0.5rem 0;
            cursor: pointer;
            transition: 0.2s;
        }
        &-button button:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }

        &-amount.active {
            background: rgba(88, 174, 255, 0.3);
            color: $strong-blue;
        }

        &-amount.active:hover {
            background: rgba(88, 174, 255, 0.3);
        }

        &-amount {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0.8rem 1.4rem;
            cursor: pointer;
            position: relative;

            .notify-amount {
                position: absolute;
                width: 20px;
                height: 20px;
                background: $red-color;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                right: 1.4rem;
                top: 50%;
                transform: translateY(-50%);
                font-size: 1.2rem;
                transition: 0.2s;
            }
        }

        &-amount-text {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            word-wrap: break-word;
            margin-left: 1.4rem;
            font-style: italic;
            font-weight: 500;
        }
        &-amount-icon {
            font-size: 1.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.4rem;
            transition: 0.2s;
        }
    }

    &-interact-amount:hover {
        background: $grey-500;
    }
}
