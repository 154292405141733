$size: 2.5rem;

.item-file {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 0.5rem 1.4rem;
    cursor: pointer;
    align-items: center;
    &--icon {
        width: $size;
        display: flex;
        align-items: center;
        svg {
            max-width: unset !important;
            width: 25px;
        }
    }

    &--name {
        margin-left: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        word-wrap: break-word;
        font-weight: 500;
    }

    &--interact {
        position: absolute;
        right: 1.4rem;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: row;
        z-index: 10;
        visibility: hidden;
    }

    &_button {
        font-size: 14px;
        padding: 0.3rem 0.5rem;
        background-color: #e8eaef;
        border-radius: 3px;
        border: 0.5px solid gray;
        cursor: pointer;

        &--mg-right {
            margin-right: 1rem;
        }
    }
}

.item-file::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;

    bottom: 0;
    right: 0;
    top: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: 0.2s;
}
.item-file:hover::after {
    opacity: 1;
}
.item-file:hover .item-file--interact {
    visibility: visible;
}
