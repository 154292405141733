@import "../../../../scss/variables";

#search-wrapper {
    background: $white-color;
    height: 100%;
    // padding: 0 1rem;

    .ant-input-affix-wrapper {
        border-radius: 18px;
    }

    .search-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1.4rem 1rem;

        div ~ div {
            margin: 0 0.2rem;
        }

        &_add-friend,
        &_create-group {
            font-size: 2rem;
            cursor: pointer;
            width: 32px;
            height: 32px;

            text-align: center;
            border-radius: 20px;
            transition: 0.2s;
        }

        &_add-friend:hover,
        &_create-group:hover {
            background-color: $light-grey;
        }

        &_input-search {
            flex-grow: 1;
        }
    }
    .search-bottom {
        .classify-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1rem;
            span:last-child {
                font-size: 1.7rem;
                font-weight: 500;
            }
        }
        .classify-element {
            padding-left: 1rem;
        }

        .add-classify {
            width: 32px;
            font-size: 2rem;
            border-radius: 20px;
            height: 32px;

            text-align: center;
            cursor: pointer;
            transition: 0.2s;
        }

        .add-classify:hover {
            background-color: $light-grey;
        }
    }
}
