$demension_icon: 26px;
.upload-cover {
    &_wrapper {
        position: relative;
        background: #c4c4c4;
        height: 100%;
    }

    &_img {
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &_icon {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        cursor: pointer;
        width: $demension_icon;
        height: $demension_icon;
        border-radius: 50%;

        background-color: #fff;

        border: 0.5px solid #c4c4c4;
        label {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    &_text-select {
        font-size: 1.4rem;
        cursor: pointer;
        text-align: center;
        width: 100%;
        display: block;
        height: 100%;
        text-align: center;
        line-height: 18rem;
    }
}
