@import "../../../../scss/variables";
.suggest_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 25rem;
    height: 25rem;
    border-radius: 5px;
    cursor: pointer;
    &-info {
        text-align: center;
        margin-top: 1rem;
        span {
            display: block;
            font-size: 1.3rem;
            color: $neutral-500;
        }
    }

    &-btn {
        margin-top: 1rem;
    }
}
