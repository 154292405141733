@import "../../../../scss/variables";
#info_friend-search {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;

    .info_friend-search-content {
        height: calc(100vh - 68px);

        padding: 1.2rem 0;
        display: flex;
        flex-direction: column;
        .info_friend-add-member {
            padding: 0 1.6rem;
            button {
                width: 100%;
                padding: 0.6rem 0;
                cursor: pointer;
                border: none;

                outline: none;
                color: $black-color;
                background: $grey-400;
                font-weight: 500;
                transition: 0.2s;
            }

            button:hover {
                background: $grey-300;
            }
        }

        .info_friend-searchbar-and-title {
            flex-direction: column;

            .info_friend-search-title {
                padding: 1rem 1.6rem;
            }

            .info_friend-searchbar {
                padding: 0 1.6rem;
            }

            .info_friend-list {
                padding: 2rem 0;

                .info_friend-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    justify-content: space-between;
                    margin-bottom: 1rem;
                    transition: 0.2s;
                    padding: 1rem 1.6rem;

                    &-leftside {
                        display: flex;
                        flex-direction: row;

                        &-name {
                            display: flex;
                            flex-direction: column;
                            font-size: 14px;
                            margin-left: 1rem;

                            align-items: center;
                            justify-content: center;

                            strong {
                                font-weight: 500;
                            }
                        }
                    }

                    &-rightside.hidden {
                        visibility: hidden;
                    }
                }

                .info_friend-item:hover {
                    background: $light-grey;
                    cursor: pointer;
                }
            }
        }
    }
}

.menu-icon {
    font-size: 1.4rem;
    font-weight: 500;
}
