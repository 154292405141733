@import "../../../../scss/variables";

.info_title {
    line-height: normal;
    height: 68px;
    display: flex;
    justify-content: center;

    align-items: center;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    position: relative;

    .back-icon {
        position: absolute;
        left: 5%;
        font-size: 18px;
        cursor: pointer;

        height: 36px;
        width: 36px;
        display: flex;

        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: 0.2s;
    }
    .select-text {
        position: absolute;
        right: 5%;
        cursor: pointer;
        font-weight: 600;

        transition: 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0;
    }

    .back-icon:hover {
        background: $white-smoke;
    }

    > span {
        font-weight: 600;
        font-size: 18px;
    }
}
