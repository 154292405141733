@import "../../scss/variables";

#main-friend_wrapper {
    .divider-layout {
        height: 2vh;
        padding: 0 1rem;
        display: flex;
        align-items: flex-end;

        // background-color: red;
        > div {
            height: 0.1px;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
    .main-friend_sidebar {
        border-right: 0.05px solid rgba(0, 0, 0, 0.2);
        &_search-bar {
            height: 10vh;
        }

        &_bottom {
            height: 88vh;

            .main-friend_sidebar_option {
                cursor: pointer;
                padding: 0 1.4rem;
                height: 72px;
                transition: 0.2s;
                display: flex;
                flex-direction: row;
                align-items: center;
                &_img img {
                    width: 52px;
                    height: 52px;
                }
                &_text {
                    font-size: 18px;
                    font-weight: 400;
                    margin-left: 1rem;
                }
            }

            .main-friend_sidebar_list-friend {
                &_title {
                    padding: 0 1.4rem;
                    height: 4vh;
                    display: flex;
                    align-items: center;
                }
                height: calc(80vh - 2vh - 4vh - 72px - 72px - 72px);
            }
            .main-friend_sidebar_option:hover {
                background: $grey-500;
            }
        }
    }

    .main-friend_body {
        background: $ghost-white;

        &__filter {
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 5rem;
        }
        &__filter--left,
        &__filter--right {
            cursor: pointer;
        }

        &_item {
            height: calc(100vh - 68px);
        }
        &_title-list {
            font-weight: 500;
            margin: 1.5rem 5rem;
        }

        &__list-group {
            padding: 0 5rem;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
    }

    @media only screen and (min-width: 577px) and (max-width: 767px) {
    }
    @media only screen and (max-width: 576px) {
        .main-friend_body__filter {
            padding-left: 2rem;
            padding-right: 2rem;

            flex-direction: column;
            align-items: flex-start;

            height: auto;
        }
        .main-friend_body_title-list {
            margin-left: 2rem;
            margin-right: 2rem;
        }
        .main-friend_body__list-group {
            padding: 0 2rem;
        }
    }
}
