@import "../../../../scss/variables";
.reply-block {
    height: 60px;
    background: rgba(0, 0, 0, 0.04);
    margin: 1rem 1.1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2rem;
    position: relative;
    max-width: 100%;
    overflow: hidden;

    .vertical-bar {
        position: absolute;
        height: 40px;
        width: 2px;
        background: $base-color;
        transform: translateX(-1rem);
    }

    &_close-btn {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        cursor: pointer;
        display: inline-grid;
    }

    &_info--user {
        font-weight: 500;
    }

    &_info {
        margin-left: 1rem;
        font-size: 1.3rem;
    }

    &_logo {
        width: 36px;
        height: 36px;
        overflow: hidden;
        border-radius: 2px;
        cursor: pointer;

        svg {
            height: 36px;
            width: 36px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-position: center center;
        }
    }
}

.info-blog_info--bottom {
    display: inline-block;
    color: #72808e;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    word-wrap: break-word;
    max-width: 40vw;
}
