.link-preview-custom {
    .LowerContainer {
        .Title {
            font-size: 1.4rem;
        }
        .Description {
            font-size: 1.3rem;
        }
        .SiteDetails {
            font-size: 1.3rem;
        }
    }
}
.text-message-item {
    .tag-user {
        color: #61a7ff;
    }
    .tag-user:hover {
        cursor: pointer;
    }
    .content-single-link {
        margin-bottom: 1rem;
    }
    .content-mutiple-link a:hover {
        text-decoration: underline;
    }
}
